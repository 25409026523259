import React from 'react';

import styled from 'styled-components';

import Layout from '../components/layout';

const Container = styled.div`
  padding: 2rem;
  text-align: center;
  width: 100%;
`;

export default function Masonry() {
  return (
    <Layout>
      <Container />
    </Layout>
  );
}
